<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      
      <form class="subscription-form" @submit.prevent="createSubscription">
        <h1>Add Subscription</h1>
        
        <div>
          <!-- Name Field -->
          <div class="mb-3">
            <label for="name" class="form-label">Name:</label>
            <input type="text" id="name" name="name" v-model="name" class="form-control" placeholder="Enter subscription name here" required />
          </div>

          <!-- Description Field -->
          <div class="mb-3">
            <label for="description" class="form-label">Description:</label>
            <input type="text" id="description" name="description" v-model="description" class="form-control" placeholder="Enter subscription description here" required />
          </div>

          <!-- Amount Field -->
          <div class="mb-3">
            <label for="amount" class="form-label">Amount:</label>
            <input type="number" id="amount" name="amount" v-model="amount" class="form-control" placeholder="Enter amount here" required />
          </div>

          <!-- Transactions Field -->
          <div class="mb-3">
            <label for="transactions" class="form-label">Transactions:</label>
            <input type="text" id="transactions" name="transactions" v-model="transactions" class="form-control" placeholder="Enter transactions here" required />
          </div>

          <!-- Support Type Dropdown -->
          <div class="mb-3">
            <label for="editSupportType" class="form-label">Support Type</label>
            <select
              id="editSupportType"
              v-model="supportType"
              class="form-control"
              required
            >
              <option value="0">Dedicated Support</option>
              <option value="1">Premium Support</option>
            </select>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="text-center mt-3">
          <button type="submit" class="btn btn-primary">Create Subscription</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";

export default {
  name: "CreateSubscriptions",

  components: {
    Navbar,
    LeftMenu,
  },

  data: function() {
    return {
      name: "",
      description: "",
      amount: "",
      transactions: "",
      supportType: "0", // Default to "Dedicated Support"
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["sendVerifyResendRequest"]),

    createSubscription() {
      const requestData = {
        name: this.name,
        description: this.description,
        amount: this.amount,
        transactions: this.transactions,
        support_type: this.supportType, // Include support type
      };

      axios
        .post(process.env.VUE_APP_API_URL + "createSubscriptionPlan", requestData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            console.log(response);
            this.$router.push("/Subscriptions");
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to create subscription plan.");
        });
    },
  },
};
</script>

<style scoped>
.subscription-form {
  background-color: #f5f5f5;
  padding: 20px;
  border: 1px solid #ddd;
  margin: 0 auto;
  border-radius: 15px;
  min-width: 500px;
}

.subscription-form h1 {
  margin-bottom: 20px;
  text-align: center;
}

.subscription-form label {
  display: block;
  margin-bottom: 5px;
  font-family: var(--outfit);
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #343a40;
}

.subscription-form input,
.subscription-form select {
  width: 100%;
  height: 48px;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  padding-left: 16px;
  border-radius: 5px;
}

.subscription-form button {
  background-color: rgb(5, 66, 85);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.subscription-form button:hover {
  background-color: rgb(0, 45, 60);
}
</style>
